import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/MeasureLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Stck Language Spec`}</h1>
    <p>{`The Stck language is defined by the following specification.`}</p>
    <h2>{`Comments`}</h2>
    <p>{`Comments start with `}<inlineCode parentName="p">{`#`}</inlineCode>{` and extend to the end of the line.`}</p>
    <h2>{`Primitives`}</h2>
    <p>{`Stck uses two primary data types.`}</p>
    <ul>
      <li parentName="ul">{`Numbers. `}<em parentName="li">{`e.x. `}<inlineCode parentName="em">{`-1, 1.2, 4, 0`}</inlineCode></em></li>
      <li parentName="ul">{`Strings. `}<em parentName="li">{`e.x. `}<inlineCode parentName="em">{`"hello"`}</inlineCode></em></li>
    </ul>
    <h2>{`Stacks`}</h2>
    <p>{`Stacks are the only data structure in stck. They are identified using a sequence
of alpha characters. They can be pushed to or popped from.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`1>a`}</inlineCode>{`: Push value `}<inlineCode parentName="li">{`1`}</inlineCode>{` onto stack a`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`a>b`}</inlineCode>{`: Pop from stack a and push value to stack b`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`a>`}</inlineCode>{`: Pop from stack a`}</li>
    </ul>
    <p>{`Popping from an empty stack always returns 0.`}</p>
    <p>{`There are two special stacks:`}</p>
    <ul>
      <li parentName="ul">{`Stack `}<inlineCode parentName="li">{`i`}</inlineCode>{` is read only and contains the standard input.`}</li>
      <li parentName="ul">{`Stack `}<inlineCode parentName="li">{`o`}</inlineCode>{` is write only. Values pushed to this stack are sent to standard output.`}</li>
    </ul>
    <h2>{`Operators`}</h2>
    <p>{`In addition to popping and pushing, stacks can be manipulated with operators.`}</p>
    <h3>{`Math: `}<inlineCode parentName="h3">{`+, -, *, /`}</inlineCode></h3>
    <p><em parentName="p">{`In the following examples, addition can be replaced with any of the
mathematical operators.`}</em></p>
    <p>{`Syntax: `}<inlineCode parentName="p">{`stack+operand`}</inlineCode>{` or `}<inlineCode parentName="p">{`stack+stack`}</inlineCode>{` or `}<inlineCode parentName="p">{`stack+`}</inlineCode></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`a+b`}</inlineCode>{`: Pop from a and b, perform addition, push the result to a.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`a+`}</inlineCode>{`: Pop two values from a, perform addition, push the result to a.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`a+4`}</inlineCode>{`: Pop from a, perform addition with the literal 4, push the result to a.`}</li>
    </ul>
    <h3>{`Clear: `}<inlineCode parentName="h3">{`?`}</inlineCode></h3>
    <p>{`Syntax: `}<inlineCode parentName="p">{`stack?`}</inlineCode></p>
    <p>{`Clears the stack if the topmost value is 0.`}</p>
    <h2>{`Functions`}</h2>
    <p>{`Functions are defined between curly braces.`}</p>
    <p>{`Syntax: `}<inlineCode parentName="p">{`{:name BODY}`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{:name
  # function body
}
`}</code></pre>
    <p>{`When a function is called, the function body is executed. Functions can
only be defined at the top level of a program.`}</p>
    <h2>{`Loops`}</h2>
    <p>{`Loops are defined between parens`}</p>
    <p>{`Syntax: `}<inlineCode parentName="p">{`(stack BODY)`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`(n
  # loop body
)
`}</code></pre>
    <p>{`A loop will execute until the stack is empty.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      